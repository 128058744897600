import { Link } from 'gatsby';
import * as moment from 'moment';
import * as React from 'react';
import styled from '../../styled-components';
import { joinPath } from '../../utils';
import { NewsArticleProps } from '../types';

const ArticleDate = styled(
  ({ date, className }: { date: string; className?: string }) => (
    <p className={className}>{moment(date).format('Do MMMM YYYY')}</p>
  )
)`
  margin-bottom: ${props => props.theme.baseMargin}rem;
  color: white;
`;

const ArticleInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${props => props.theme.baseMargin * 0.5}rem;
  > *:last-child {
    margin-bottom: 0;
  }
`;

const NewsSidebarItem = styled(
  ({
    className,
    title,
    headerTitle,
    slug,
    image,
    copy,
    date,
    parentSection
  }: NewsArticleProps & { className?: string }) => (
    <Link to={joinPath(parentSection.slug, slug)} className={className}>
      {image ? (
        <img src={image?.resolutions?.src} style={{ height: 150 }} />
      ) : null}
      <ArticleInfo>
        <h3>{headerTitle || title}</h3>
        <ArticleDate date={date} />
      </ArticleInfo>
    </Link>
  )
)`

  background-color: ${props => props.theme.secondaryColor};
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0
  border-radius: 0.3rem;
  height: auto;

  margin-bottom: ${props => props.theme.baseMargin}rem;

  h3 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  img {
    min-height: 150px;
    object-fit: cover;
  }
`;

export default NewsSidebarItem;
