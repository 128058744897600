import { graphql } from 'gatsby';
import * as React from 'react';
import { Container } from 'styled-bootstrap-grid';
import AlertList from '../components/AlertList';
import { Column, Row } from '../components/Grid';
import Layout from '../components/Layout';
import NewsArticle from '../components/News/NewsArticle';
import NewsSidebar from '../components/News/NewsSidebar';
import SectionTitle from '../components/SectionTitle';
import {
  AllNewsArticleData,
  AllSectionProps,
  NewsArticleData,
  NewsArticleProps
} from '../components/types';
import { normalizePath } from '../utils';

export const newsArticleQuery = graphql`
  query NewsArticleQuery($id: String!) {
    ...AllSectionFragment
    contentfulNewsArticle(id: { eq: $id }) {
      ...NewsArticleFragment
    }
  }
`;

const getBreadcrumbPath = ({
  allContentfulSection,
  contentfulNewsArticle: newsArticle
}: NewsArticleData & AllSectionProps) => {
  const home = allContentfulSection.edges[0].node;
  const section = {
    slug: newsArticle.parentSection.slug,
    title: newsArticle.parentSection.title
  };
  const active = {
    slug: newsArticle.slug,
    title: newsArticle.headerTitle
  };

  return [home, section, active].map(({ slug, title }) => ({
    slug,
    title
  }));
};

const getBackgroundImage = (newsArticle: NewsArticleProps) => {
  const [pageTitle] = newsArticle?.parentSection?.pageTitle || [];
  return (pageTitle as any)?.backgroundImage;
};

const NewsArticleTemplate = ({
  data,
  location
}: {
  data: AllSectionProps & NewsArticleData & AllNewsArticleData;
  pageContext: any;
  location: { pathname: string };
}) => {
  const { contentfulNewsArticle: newsArticle } = data;
  const breadcrumbPath = getBreadcrumbPath(data);
  const currentSectionPath = normalizePath(breadcrumbPath[1].slug);
  const currentLocation = normalizePath(location.pathname);
  const backgroundImage = getBackgroundImage(newsArticle);

  return (
    <Layout
      title={data.contentfulNewsArticle.headerTitle}
      breadcrumbPath={breadcrumbPath}
      currentSectionPath={currentSectionPath}
      currentLocation={currentLocation}
      menuData={data}
    >
      <SectionTitle
        headline={newsArticle.headerTitle}
        backgroundImage={backgroundImage}
      />
      <Container>
        <AlertList />
        <Row>
          <Column md={9}>
            <NewsArticle {...newsArticle} />
          </Column>
          <Column md={3}>
            <NewsSidebar currentArticleId={newsArticle.title} />
          </Column>
        </Row>
      </Container>
    </Layout>
  );
};

export default NewsArticleTemplate;
