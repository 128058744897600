import { graphql } from 'gatsby';
import * as moment from 'moment';
import * as React from 'react';
import styled from '../../styled-components';
import CallToAction, { CallToActionContainer } from '../CallToAction';
import ContentCardCopy from '../ContentCard/ContentCardCopy';
import { NewsArticleProps } from '../types';

export const newsArticleFragment = graphql`
  fragment NewsArticleFragment on ContentfulNewsArticle {
    id
    title
    headerTitle
    slug
    date
    copy {
      childMarkdownRemark {
        html
      }
    }
    image {
      resolutions(width: 400) {
        src
      }
    }
    parentSection {
      ...SectionFragment
    }
  }
`;

const NewsArticle = styled(
  ({ className, copy, date }: NewsArticleProps & { className?: string }) => (
    <div className={className}>
      <ContentCardCopy {...copy} />
      <CallToActionContainer>
        <CallToAction
          navigateToSlug="/contact-us"
          buttonText="Contact us today"
          primaryOrSecondary="primary"
        />
      </CallToActionContainer>
    </div>
  )
)`
  background-color: ${props => props.theme.secondaryColor};
  border-radius: 0.3rem;

  padding: ${props => props.theme.baseMargin}rem;
  border-radius: 0.3rem;
  height: auto;

  margin-bottom: ${props => props.theme.baseMargin}rem;
  > * {
    margin-bottom: ${props => props.theme.baseMargin}rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default NewsArticle;
