import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import NewsSidebarItem from './NewsSidebarItem';

const NewsSidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const NewsSidebar = ({ currentArticleId }: { currentArticleId: string }) => {
  const data = useStaticQuery(graphql`
    query NewsSidebarQuery {
      allContentfulNewsArticle(
        sort: { fields: [date], order: DESC }
        limit: 10
      ) {
        edges {
          node {
            ...NewsArticleFragment
          }
        }
      }
    }
  `);

  const articles =
    data.allContentfulNewsArticle?.edges?.map((x: any) => x.node) || [];

  return (
    <NewsSidebarContainer>
      {articles.map((a: any) => (
        <NewsSidebarItem key={a.id} {...a} />
      ))}
    </NewsSidebarContainer>
  );
};

export default NewsSidebar;
